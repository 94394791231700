<template>
  <v-app>
    <v-container fluid>
      <v-row>
        <v-col sm="12" cols="12">
          <div
            style="
              background-color: #fff;
              padding: 20px 20px;
              border-radius: 10px;
            "
          >
            <v-col sm="12" cols="12">
              <v-row>
                <v-col sm="6" cols="12" class="py-0">
                  <v-snackbar
                    :color="snackbar.color"
                    v-model="snackbar.show"
                    right
                  >
                    {{ snackbar.message }}
                  </v-snackbar>
                  <v-btn
                    depressed
                    small
                    class="mx-2"
                    color="deep-purple lighten-2"
                    @click="$router.go(-1)"
                    dark
                    mdi-keyboard-backspace
                    ><v-icon left> mdi-keyboard-backspace </v-icon> Back</v-btn
                  >
                </v-col>
              </v-row>
              <div style="margin-top: 20px"></div>

              <v-row justify="center">
                <!-- <v-divider class="ma-2 mt-8 mb-5" color="#e9e9e9"></v-divider> -->

                <v-col sm="12" cols="12">
                  <v-row>
                    <v-col sm="6" cols="12" md class="text-center">
                      <div class="text-h5">Result of the Examination</div>
                      <div class="text-h6">
                        {{ listSessionDetails.academic_year }}
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col sm="6" cols="12">
                      <div class="float-left text-h7">
                        Class: {{ listSessionDetails.major }}
                      </div>
                    </v-col>
                  </v-row>

                  <div id="app">
                    <v-app>
                      <template>
                        <v-data-table
                          :headers="listHeader"
                          :items="listStudent"
                          class="elevation-1"
                          hide-default-header
                          :mobile-breakpoint="0"
                          hide-default-footer
                          disable-pagination
                        >
                          <template v-slot:header="{ props }">
                            <thead>
                              <tr>
                                <th
                                  :colspan="listHeader.length + 1"
                                  style="text-align: center"
                                >
                                  {{ listSessionDetails.major_gen }}
                                </th>
                              </tr>
                              <tr>
                                <th
                                  :colspan="listHeader.length + 1"
                                  style="text-align: center"
                                >
                                  {{ listSessionDetails.session_name }}
                                </th>
                              </tr>
                              <tr>
                                <th class="text-center">No</th>
                                <th
                                  v-for="header in props.headers"
                                  :key="header.text"
                                  class="text-center"
                                >
                                  <span
                                    v-if="header.text == 'Student ID'"
                                    style="text-align: center"
                                  >
                                    <th rowspan="2">{{ header.text }}</th>
                                  </span>
                                  <span
                                    v-else-if="header.text == 'Student Name'"
                                    style="text-align: center"
                                    >{{ header.text }}</span
                                  >
                                  <span
                                    v-else-if="header.text == 'Total Score'"
                                    style="text-align: center"
                                    >{{ header.text }}
                                  </span>
                                  <span
                                    v-else-if="header.text == 'Rank'"
                                    style="text-align: center"
                                    >{{ header.text }}
                                  </span>
                                  <span v-else style="text-align: center"
                                    >{{ header.text }}
                                  </span>
                                </th>
                              </tr>
                            </thead>
                          </template>

                          <template v-slot:body="props">
                            <tr v-for="(index, i) in props.items" :key="i">
                              <td
                                class="text-center font-weight-black"
                                style="
                                  height: 35px;
                                  border: 1pt solid rgb(104, 102, 102) !important;
                                "
                              >
                                {{ 1 + i++ }}
                              </td>
                              <td
                                v-for="(header, indx) in listHeader"
                                :key="indx"
                                style="
                                  height: 35px;
                                  border: 1pt solid rgb(104, 102, 102) !important;
                                "
                                class="text-center font-weight-black"
                              >
                                <span>{{ index[header.value] }}</span>
                              </td>
                            </tr>
                          </template>
                        </v-data-table>
                      </template>
                    </v-app>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <div>
      <div class="loading">
        <loading
          :active.sync="isLoading"
          :is-full-page="fullPage"
          :opacity="0.9"
          background-color="#dedede"
          :width="40"
          :height="40"
        >
          <div v-if="myLoading">
            <img
              width="100%"
              src="https://firebasestorage.googleapis.com/v0/b/schoolbase.appspot.com/o/images%2Floading.gif?alt=media&token=58553b10-7335-42a6-a0c8-8a6d55927816"
            />
          </div>
        </loading>
      </div>
    </div>
  </v-app>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import store from "@/store";
import { getSessionScoreByStudent } from "@schoolbase/web-client-lib";

export default {
  props: ["fullPage", "alertMessage", "color", "alertText"],
  name: "app",
  data() {
    return {
      id: "",

      id_student: "",
      token: store.getters.getToken,
      approveBool: "",
      isLoading: false,
      myLoading: false,
      dataApprove: "",
      snackbar: {
        show: false,
        message: null,
        color: null,
      },
      listSessionScore: [],
      listHeader: [],
      listSessionDetails: [],
      approve: "",
      listStudent: [],
    };
  },
  components: {
    Loading,
  },
  computed: {},
  methods: {
    async loadSessionScore() {
      try {
        this.isLoading = true;
        this.myLoading = true;
        const APIResponse = await getSessionScoreByStudent(
          this.$route.params.student_id,
          this.$route.params.sess_id,
          this.token
        );
        this.listHeader = APIResponse.payload.headers;
        this.listStudent = APIResponse.payload.student;

        this.listSessionScore = APIResponse.payload;
        this.listSessionDetails = APIResponse.payload.session;
        if (APIResponse.token) {
          this.$store.commit("LOGGED_TOKEN", APIResponse.token);
        }

        this.isLoading = false;
        this.myLoading = false;
      } catch (e) {
        this.isLoading = false;
        this.myLoading = false;

        // Logout user when unauthorized call
        if (e.response?.status == 401) {
          this.$store.dispatch("logOut");
          this.$router.go({
            name: "SignIn",
          });

          return;
        }

        this.snackbar = {
          message: e,
          color: "error",
          show: true,
        };
      }
    },
  },
  async mounted() {
    this.loadSessionScore();
  },
};
</script>
<style scoped>
.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  font-family: "Krasar-Bold", serif;
  font-size: 16px !important;
  color: black !important;
  border: 1pt solid rgb(104, 102, 102) !important;
}
</style>
